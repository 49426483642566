import * as React from 'react';
import { useGetUserProperties } from '../apollo/queries'

const TestPage = () => {
  const { data } = useGetUserProperties()
  console.log(data?.homeowner);
  return (
    <>hi</>
  )
}

export default TestPage
